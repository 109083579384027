@media (max-width: 1400px) {
  .navbar-nav.languages-list {
    right: 0px;
    z-index: 100;
  }
}

@media (max-width: 1199px) {
  .app_banner_area {
    background-position: left;
    position: relative;
  }

  .app_banner_area .new_startup_img img {
    max-width: 100%;
  }

  .app_banner_area .new_startup_img {
    padding-right: 20px;
  }

  .seo_features_content .seo_features_item {
    padding-right: 0;
  }

  .menu_four .btn_get_radious {
    margin-left: 20px;
  }

  .menu_four .btn_language {
    margin-left: 20px;
  }
}

@media (max-width: 991px) {
  .h_analytices_features_item .h_analytices_content {
    padding-right: 0px;
  }

  .app_banner_area {
    min-height: 100vh;
    padding: 150px 0px 100px;
    background-position-x: -200px;
  }

  .app_banner_area .new_startup_img {
    padding-right: 0;
    padding-bottom: 50px;
    display: none;
  }

  .seo_service_item h4 {
    padding-right: 35px;
  }

  .sec_pad {
    padding: 70px 0px;
  }

  .seo_features_img img {
    width: 70%;
  }

  .startup_tab .nav-item {
    padding: 5px 15px;
  }

  .startup_tab .nav-item .nav-link {
    padding: 5px 0px;
  }

  .menu_four .btn_language {
    margin-left: 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
  }

  .menu_four .btn_language.menu {
    padding-top: 0px;
  }

  .header_area .menu_four .menu_custfive {
    border: none;
    color: #2e54cc;
    margin-left: 20px;
    padding: 0;
    min-width: unset;
    font-size: 15px;
    line-height: unset;
  }

  .navbar-nav.languages-list {
    position: unset;
    width: 100%;
  }

  .navbar-nav.languages-list .nav-item {
    display: flex;
    align-items: center;
    padding-top: 20px;
  }

  .menu_four .btn_language_label.selected {
    color: #051441;
  }

  .menu_four .btn_language i {
    color: #333;
  }

  .menu_four .btn_language ul.dropdown-menu {
    position: static;
  }

  .footer_area_four .footer_top {
    padding-top: 60px;
  }

  .how-we-work,
  .our-office {
    padding: 70px 0px 70px;
    position: relative;
    z-index: 0;
  }
}

@media (max-width: 768px) {
  .breadcrumb_area:not(.no-full-height) {
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 575px) {
  .app_banner_area {
    background-position-x: -600px;
  }

  .p_service_item.agency_service_item {
    text-align: center;
  }

  .p_service_item {
    text-align: center;
    padding: 0px !important;
  }

  .p_service_item .icon {
    margin: auto auto 25px;
  }

  .sec_pad {
    padding: 50px 0px;
  }

  .seo_features_img {
    margin-bottom: 30px;
  }

  .react-cookie-banner {
    font-size: 12px !important;
  }

  .how-we-work,
  .our-office {
    padding: 50px 0px 50px;
    position: relative;
    z-index: 0;
  }
}

@media (min-width: 992px) {
  .pt_lg_150 {
    padding-top: 150px;
  }
}

@media (max-width: 576px) {
  .action_area_three .action_content .white_btn {
    margin-right: 10px;
  }

  .nav-link {
    padding: 0.5rem 0rem 0.5rem 0rem;
    margin-right: 10px;
  }

  .breadcrumb_area.services_area .breadcrumb_shap {
    object-position: -480px;
  }
}

@media (min-width: 576px) {
  .action_area_three a {
    font-size: 16px;
  }
}
@media (max-width: 359px) {
  .nav-link {
    margin-right: 0px;
  }
}

.portfolio_details_info {
  white-space: pre-line;
}

@media (min-width: 1199px) {
  .seo_features_img img {
    top: 0px;
  }
}

@media (min-width: 1250px) {
  .seo_features_img .business_card {
    top: 102%;
  }
}

@media (max-width: 1199px) {
  .seo_features_img img {
    width: 120%;
    max-width: 120%;
    position: absolute;
    left: 5%;
    top: -8px;
  }
}

@media (max-width: 992px) {
  .ai-landing-left-column {
    margin-bottom: 70px;
  }

  .seo_features_img img {
    width: 50%;
    max-width: 120%;
    position: absolute;
    left: 35%;
    top: -8px;
  }

  .contact_info_area .banner_shap {
    width: 118%;
    height: unset;
    bottom: 0px;
    max-width: 159%;
    right: -29%;
  }

  .seo_features_img {
    min-height: 300px;
  }

  .seo_features_img .business_card {
    right: 63%;
  }
}

@media (max-width: 768px) {
  .contact_info_area .banner_shap {
    width: 150%;
  }
}

@media (max-width: 650px) {
  .seo_features_img img {
    top: 34px;
  }

  .seo_features_img .business_card {
    top: 90%;
  }
}

@media (max-width: 490px) {
  .seo_features_img img {
    width: 65%;
  }

  .seo_features_img .business_card {
    top: 85%;
  }
}

@media (max-width: 576px) {
  .ai-landing-left-column {
    margin-bottom: 40px;
  }
}

.container.carousel {
    margin: 0 auto 50px;
}

.carousel-title {
    margin: 0 0 30px;
}

.carousel-item {
    display: flex !important; /* Ovveride slick issue **/
    max-width: 300px;
}

.carousel-item > .gap {
    margin-right: 35px;
}

.carousel-item .img-box {
    position: relative;
    margin-bottom: 20px;
}

.carousel-item .img-box > img {
    max-width: 100%;
}

.carousel-item .description-bg {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    width: 100%;
    height: 100%;
    background: rgba(44, 44, 57, 0.7);
    color: white;
    font-size: 12px;
    line-height: 18px;
    opacity: 0;
    transition: opacity .3s linear;
}

.carousel-item:hover {
    cursor: pointer;
}

.carousel-item:hover .description-bg {
    opacity: 1;
}

.carousel-item .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid white;
    border-radius: 50%;
}

.carousel-item .arrow:hover {
    background: rgb(255 255 255 / 50%);;
    transition: background .3s linear;
}

.carousel-item .description {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.carousel-item .title {
    color: #282835;
    transition: color .3s linear;
}

.carousel-item:hover .title,
.carousel-item .type {
    color: #2e54cc;
}

.carousel-item .dash {
    margin: 0 8px;
    color: #aeaeae;
}

.carousel-item .type {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
}

.carousel-buttons {
    margin: 35px 0;
    text-align: center;
}

@media (max-width: 480px) {
    .carousel-item {
        max-width: 100%;
        justify-content: center;
    }

    .carousel-item > .gap {
        margin: 0;
    }

    .carousel-buttons {
        margin: 20px 0;
    }
}